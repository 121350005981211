import { Button, Form, Input, Select, Space, Table, DatePicker } from 'antd';
import { useContext, useState } from 'react';
import CreateDrawer from '../../../components/create';
import { PageContext } from '../../../lib/context';
import ReportForm from './forms';
import ReportMessages from './messages';

const ReportsView = () => {
  const [showMessages, setShowMessages] = useState<boolean>(true);

  const {
    datas,
    usersColumns,
    _submitHandler,
    searchForm,
    _onFinishSearch,
    _searchFormChangeHandler,
    categories,
    statuses,
  } = useContext(PageContext);

  const { RangePicker } = DatePicker;

  return (
    <div className="flex flex-col px-10 h-screen">
      <div className="flex justify-between">
        <div className="pt-5">
          <p className="text-3xl font-bold">Reports</p>
          <div className="flex flex-row mb-5">
            <Form layout="inline" form={searchForm} onFinish={_onFinishSearch}>
              <Form.Item name="date">
                <RangePicker size="large" />
              </Form.Item>
              <Form.Item name="title">
                <Input
                  style={{ width: 250 }}
                  placeholder="Search by Title"
                  allowClear
                  size="large"
                />
              </Form.Item>
              <Form.Item name="category_id">
                <Select
                  allowClear
                  showSearch
                  style={{ width: 250 }}
                  size="large"
                  placeholder="Filter by Category"
                  filterOption={(input: any, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {categories.map(({ id, name }: any) => (
                    <Select.Option key={`${id}`} value={id}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="status">
                <Select
                  allowClear
                  showSearch
                  style={{ width: 250 }}
                  size="large"
                  placeholder="Filter by Status"
                  filterOption={(input: any, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {statuses.map(({ id, text }: any) => (
                    <Select.Option key={`${id}`} value={id}>
                      {text}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
                <Button type="primary" size="large" htmlType="submit">
                  SEARCH
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
        <Space>
          <CreateDrawer
            show_create={false}
            title={'Report Information'}
            buttonTitle="Create Report"
            _submitHandler={_submitHandler}
            addForm={true}
            width={'40%'}
          >
            <ReportForm />
          </CreateDrawer>
          <ReportMessages
            title={'Report Messages'}
            width={'40%'}
          ></ReportMessages>
        </Space>
      </div>
      <div className="flex flex-row">
        <div className="w-full mr-3">
          <Table
            dataSource={datas}
            columns={usersColumns}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '30'],
            }}
            rowKey={'id'}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportsView;
