import axios from "axios";

const auth = {
  isAuthenticated() {
    return this.getUserInfo() as boolean;
  },
  storeToken(token: string) {
    localStorage.setItem('token', token);
  },
  getToken() {
    return localStorage.getItem('token');
  },
  getExpiration() {
    const token = this.getToken();
    if (token) {
      const decodedData = this.decode(token as string);
      return decodedData.exp;
    }
    return 0;
  },
  getRole() {
    const token = this.getToken();
    if (token) {
      const decodedData = this.decode(token as string);
      return decodedData.role;
    }
    return null;
  },
  getUserInfo() {
    const token = this.getToken();
    if (token) {
      const decodedData = this.decode(token as string);
      return decodedData.user;
    }
    return null;
  },
  decode(token: string) {
    let base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(window.atob(base64));
  },
  login(credential: object) {
    return fetch(`${process.env.REACT_APP_API_URL}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(credential),
    });
  },
  register(data: object) {
    return fetch(`${process.env.REACT_APP_API_URL}/signup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },
  clear() {
    localStorage.clear();
  },

  cloudinary: async (file: any) => {
    const api = axios.create({
      baseURL: process.env.REACT_APP_CLOUDINARY,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    const payload = new FormData();
    payload.append('file', file);
    payload.append('upload_preset', process.env.REACT_APP_PRESET??'');

    const response = await api
      .post('upload', payload)
      .then(res => {
        if (res.status === 200) {
          return {
            success: true,
            url: res.data.url,
          };
        }
      })
      .catch(err => {
        console.log(err);
        return {
          success: false,
        };
      });
    return response;
  },
};

export default auth;
