import { Col, Form, Input, Row, Space, Table } from 'antd';
import { useContext, useState } from 'react';
import CreateDrawer from '../../../components/create';
import { PageContext } from '../../../lib/context';
import CategoryForm from './forms';

const CategoriesView = () => {
  const {
    datas,
    usersColumns,
    _submitHandler,
  } = useContext(PageContext);

  return (
    <div className="flex flex-col px-10 h-screen">
      <div className="flex justify-between">
        <div className="pt-5">
          <p className="text-3xl font-bold">Categories</p>
        </div>
        <Space>
          <CreateDrawer
            title={'Category Information'}
            buttonTitle="Create Category"
            _submitHandler={_submitHandler}
            addForm={true}
            width={'40%'}
          >
            <CategoryForm></CategoryForm>
          </CreateDrawer>
        </Space>
      </div>
      <div className="flex flex-row">
        <div className="w-full ">
          <Table
            dataSource={datas}
            columns={usersColumns}
            rowKey={'id'}
            pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30']}}
          />
        </div>
      </div>
    </div>
  );
};

export default CategoriesView;
