import { gql } from '@apollo/client';

export const subscriptions = {
  GET_METERS: gql`
    subscription ($where: meters_bool_exp) {
      meters(where: $where, order_by: { id: desc }) {
        id
        serial_number
        model
        updated_at
        brand
        created_at
        is_taken

        meter_readings {
          battery
          updated_at
          meter_id
          value
          is_billed
          id
          date_time
          created_at
        }
      }
    }
  `,

  GET_MESSAGES: gql`
    subscription ($where: mandaue_messages_bool_exp) {
      data: mandaue_messages(where: $where, order_by: { created_at: asc }) {
        id
        message
        report_id
        mobile_number_id
        user_id
        sender_type
      }
    }
  `,
  GET_NEW_REPORTS_COUNT: gql`
    subscription {
      data: mandaue_reports_aggregate(where: { is_open: { _eq: false } }) {
        aggregate {
          count
        }
      }
    }
  `,
};
