import { useContext } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Drawer } from 'antd';
import { PageContext } from '../lib/context';

const CreateDrawer = ({
  children,
  title,
  _submitHandler,
  ref,
  show_create = true,
  buttonTitle = null,
  addForm = false,
  width = 800,
}: any) => {
  const { show, _showHandler, _closeHandler } = useContext(PageContext);
  return (
    <>
      {show_create ? (
         <Button type="primary" onClick={_showHandler.bind(this, addForm)}>
          <PlusOutlined /> {buttonTitle ?? title}
        </Button>
      ) : null}
      <Drawer
        visible={show}
        width={width}
        closable={true}
        onClose={_closeHandler}
        title={title}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button
              onClick={_closeHandler.bind(this)}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button onClick={_submitHandler} type="primary">
              Submit
            </Button>
          </div>
        }
      >
        {children}
      </Drawer>
    </>
  );
};

export default CreateDrawer;
