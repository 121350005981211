import { Space, Table } from 'antd';
import { useContext } from 'react';
import CreateDrawer from '../../../components/create';
import { PageContext } from '../../../lib/context';
import FeedForm from './forms';

const FeedsView = () => {
  const { datas,
    usersColumns,
    _submitHandler } = useContext(PageContext);

  console.log(datas, 'feeds');

  return (
    <div className="flex flex-col px-10 h-screen">
      <div className="flex justify-between">
        <div className="pt-5">
          <p className="text-3xl font-bold">Feeds</p>
        </div>
        <Space>
          <CreateDrawer
            title={'Feed Information'}
            buttonTitle="Create Feed"
            _submitHandler={_submitHandler}
            addForm={true}
            width={'40%'}
          >
           <FeedForm/>
          </CreateDrawer>
        </Space>
      </div>
      <div className="flex flex-row">
        <div className="w-full mr-3">
          <Table
            dataSource={datas}
            columns={usersColumns}
            rowKey={'id'}
            pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30']}}
          />
        </div>
      </div>
    </div>
  );
};

export default FeedsView;
