import { useEffect, useState } from 'react';
import { Badge, Layout, Menu } from 'antd';
import {
  UserOutlined,
  DollarCircleOutlined,
  DashboardOutlined,
  CommentOutlined,
  LogoutOutlined,
  SwitcherOutlined,
  WarningOutlined,
} from '@ant-design/icons';
// import logo from '../assets/water-drop.png';
import logo from '../assets/new-logo.png';
import { useHistory } from 'react-router-dom';
import SubMenu from 'antd/lib/menu/SubMenu';
import auth from '../lib/services';
import { useSubscription } from '@apollo/client';
import { subscriptions } from '../utils/gql/subscriptions';

interface Props {
  children: any;
}

const { Sider, Content } = Layout;

export default function DashLayout({ children }: Props) {
  const [collapsed] = useState(false);
  const [newReport, setNewReport] = useState(0);
  const history = useHistory();
  const [sideBarMenuKey, setSideBarMenuKey] = useState('0');

  const { data } = useSubscription(subscriptions.GET_NEW_REPORTS_COUNT, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (data) {
      setNewReport(data.data.aggregate.count);
    }
  }, [data]);

  const logout = () => {
    localStorage.removeItem('token');
    history.push('/');
  };

  const goTo = (url: string) => {
    history.push(url);
  };

  const role = auth.getRole();
  const active_nav = localStorage.getItem('active-nav') ?? '0';

  const menuList = [
    // {
    //   title: 'Dashboard',
    //   link: '/dashboard',
    //   icon: <DashboardOutlined />,
    // },
    {
      title: 'Users',
      link: '/users',
      icon: <UserOutlined />,
    },
    {
      title: 'Reports',
      link: '/reports',
      icon: <WarningOutlined />,
    },
    {
      title: 'Feeds',
      link: '/feeds',
      icon: <CommentOutlined />,
    },
    {
      title: 'Categories',
      link: '/categories',
      icon: <SwitcherOutlined />,
    },
    {
      title: 'Logout',
      link: '/logout',
      icon: <LogoutOutlined />,
    },
  ];

  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed} width="auto">
        <div className="mb-5 flex flex-row bg-white py-3 justify-center pr-3 items-center">
          <img src={logo} className="h-10" alt="logo" />
          <p className="mt-5 font-bold">Emergency Alert System</p>
        </div>
        <Menu theme="dark" mode="inline" selectedKeys={[active_nav]}>
          <Menu.Item
            key={0}
            icon={<UserOutlined />}
            onClick={() => {
              localStorage.setItem('active-nav', '0');
              goTo('/users');
            }}
          >
            Users
          </Menu.Item>
          <Menu.Item
            key={1}
            icon={<WarningOutlined />}
            onClick={() => {
              localStorage.setItem('active-nav', '1');
              goTo('/reports');
            }}
          >
            <Badge count={newReport} offset={[55, 8]}>
              Reports
            </Badge>
            {/* Reports */}
          </Menu.Item>
          <Menu.Item
            key={2}
            icon={<CommentOutlined />}
            onClick={() => {
              localStorage.setItem('active-nav', '2');
              goTo('/feeds');
            }}
          >
            Feeds
          </Menu.Item>
          <Menu.Item
            key={3}
            icon={<SwitcherOutlined />}
            onClick={() => {
              localStorage.setItem('active-nav', '3');
              goTo('/categories');
            }}
          >
            Categories
          </Menu.Item>
          <Menu.Item
            key={4}
            icon={<LogoutOutlined />}
            onClick={() => {
              localStorage.setItem('active-nav', '4');
              goTo('/logout');
            }}
          >
            Logout
          </Menu.Item>
          {/* {menuList.map((menu, key) => {
            return (
              <Menu.Item
                key={key}
                icon={menu.icon}
                onClick={() => { localStorage.setItem('active-nav', key.toString()); goTo(menu.link) }}
              >
                {menu.title}
              </Menu.Item>
            )
          })} */}
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Content
          style={{
            height: '100vh',
            paddingTop: 50,
            overflow: 'auto',
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
}
