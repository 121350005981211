import {
  Button,
  Col,
  Dropdown,
  Form,
  Image,
  Input,
  InputNumber,
  Row,
  Select,
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { PageContext } from '../../../lib/context';
import TextArea from 'antd/lib/input/TextArea';
import { useLazyQuery } from '@apollo/client';
import queries from '../../../utils/gql/queries';
import CreateDrawer from '../../../components/create';
import { useHistory } from 'react-router-dom';
import ToFeeds from './to-feeds';

const ReportForm = () => {
  const history = useHistory();
  const { form, _onFinish, statuses, image, _submitHandler } =
    useContext(PageContext);
  const [categories, setCategories] = useState([]);
  const [show, setShow] = useState(false);
  const [report, setReport] = useState(false);
  const [mobiles, setMobiles] = useState([]);

  const [getCategories, { data }] = useLazyQuery(queries.GET_CATEGORIES, {
    fetchPolicy: 'no-cache',
  });

  const [getMobileNumbers, { data: mobile_data }] = useLazyQuery(
    queries.GET_MOBILE_NUMBERS,
    {
      fetchPolicy: 'no-cache',
    }
  );

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  useEffect(() => {
    getMobileNumbers();
  }, [getMobileNumbers]);

  useEffect(() => {
    if (data) {
      setCategories(data.data);
    }
  }, [data]);

  useEffect(() => {
    if (mobile_data) {
      setMobiles(mobile_data.data);
    }
  }, [mobile_data]);

  const HandlesendToFeeds = () => {
    console.log(form.getFieldValue(), 'sadasd');
    setReport(form.getFieldValue());
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <Form layout="vertical" form={form} onFinish={_onFinish}>
        <Row gutter={24}>
          <Form.Item name="id" hidden={true}>
            <InputNumber />
          </Form.Item>
          <Col span={24}>
            <Form.Item
              label="Title"
              name="title"
              rules={[{ required: true, message: 'Title is required' }]}
            >
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: true, message: 'Description is required' }]}
            >
              {/* <Input /> */}
              <TextArea readOnly />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Location"
              name="location"
              rules={[{ required: true, message: 'Location is required' }]}
            >
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Category"
              name="category_id"
              rules={[{ required: true, message: 'Category is required' }]}
            >
              <Select
                disabled
                showSearch
                filterOption={(input: any, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {categories.map(({ id, name }: any) => (
                  <Select.Option key={`${id}`} value={id}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="From"
              name="mobile_number_id"
              rules={[{ required: true, message: 'Mobile is required' }]}
            >
              <Select
                showSearch
                disabled
                filterOption={(input: any, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {mobiles.map(({ id, mobile_number }: any) => (
                  <Select.Option key={`${id}`} value={id}>
                    {mobile_number}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="CHANGE STATUS"
              name="status"
              rules={[{ required: true, message: 'Mobile is required' }]}
            >
              <Select
                showSearch
                filterOption={(input: any, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {statuses.map(({ id, text }: any) => (
                  <Select.Option key={`${id}`} value={id}>
                    {text}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {image && (
            <Col span={24}>
              <Image width={200} src={image} />
            </Col>
          )}
          {/* <CreateDrawer
            title={'Feed Information'}
            buttonTitle="Create Feed"
            _submitHandler={_submitHandler}
            width={'40%'}
          ></CreateDrawer> */}
          <ToFeeds
            show={show}
            report={report}
            handleClose={handleClose}
          ></ToFeeds>
          <Button className="ml-3" danger onClick={HandlesendToFeeds}>
            Create A feeds
          </Button>
        </Row>
      </Form>
    </>
  );
};

export default ReportForm;
