import { ApolloProvider } from '@apollo/client';
import './App.css';
import { client } from './lib/client';
import Router from './router/index';
import { useEffect } from 'react';
import { useClearCache } from 'react-clear-cache';

function App() {
  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  const new_client = client;

  useEffect(() => {
    if (!isLatestVersion) {
      emptyCacheStorage();
    }
  }, [isLatestVersion]);

  return (
    <ApolloProvider client={new_client}>
      <div className="App">
        <Router />
      </div>
    </ApolloProvider>
  );
}

export default App;
