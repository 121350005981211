import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import DashLayout from '../layouts/dash.tsx';
import auth from '../lib/services';

export const AuthGuard = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        !auth.isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to={'/users'} />
        )
      }
    />
  );
};

export const DashGuard = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        auth.isAuthenticated() ? (
          <DashLayout>
            <Component {...props} />
          </DashLayout>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};
