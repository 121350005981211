import {
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from '@apollo/client';
import { PageContext } from '../../../lib/context';
import mutations from '../../../utils/gql/mutations';
import SubscribersView from '../customers/customers';
import { Button, Form, message, Space, Tag, Tooltip, Modal } from 'antd';
import { useEffect, useState } from 'react';
import moment from 'moment';
import queries from '../../../utils/gql/queries';
import {
  ContainerOutlined,
  EyeOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  DeleteRowOutlined,
  EditFilled,
  EditOutlined,
} from '@ant-design/icons';
import SubscriberView from './view';
import { useParams } from 'react-router';
import philippines from '../../../assets/philippines.json';
import { subscriptions } from '../../../utils/gql/subscriptions';
import auth from '../../../lib/services';

const { confirm } = Modal;

interface Customer {
  id: number;
  first_name: string;
  last_name: string;
  name: string;
  barangay: string;
  created_at: Date;
  comsumer_type: number;
  complete_address: string;
  meter_id: number;
  middle_name: string;
  mobile_number: string;
  municipality: string;
  zip_code: string;
}

interface Meter {
  id: number;
  serial_number: string;
}

interface Rate {
  id: number;
  name: string;
}
interface AdditionalFees {
  id: number;
  title: string;
}

interface CustomAddFees {
  title: string;
  amount: number;
}

// PAYMENTS COLUMN //
const subPayColumn = [
  {
    title: 'Payment ID',
    key: 'id',
    dataIndex: 'id',
  },
  {
    title: 'Bill Total Balance',
    key: 'total_balance',
    render: (item: any) => Math.round(item?.bill?.total_balance * 100) / 100,
  },
  {
    title: 'Paid Amount',
    key: 'amount',
    render: (item: any) => Math.round(item?.amount * 100) / 100,
  },
  {
    title: 'Paid Date',
    key: 'date',
    render: (item: any) => moment(`${item?.date}`).format('LL'),
  },
];

const SubscriberController = () => {
  const [form] = Form.useForm();
  const [reading_form] = Form.useForm();
  const [additional_fee_form] = Form.useForm();
  const [payments_form] = Form.useForm();
  const [notesForm] = Form.useForm();
  const params: any = useParams();

  // BILLS COLUMN //
  const subBillColumn = [
    {
      title: 'Bill',
      key: 'billing_id',
      dataIndex: 'billing_id',
    },
    {
      title: 'Billing Date',
      key: 'billing_date',
      render: (item: any) => moment(`${item?.billing_date}`).format('LL'),
    },
    {
      title: 'Current Reading',
      key: 'current_reading',
      render: (val: any) => val.current_reading_backup ?? val.current_reading,
    },
    {
      title: 'Previous Reading',
      key: 'previous_reading',
      dataIndex: 'previous_reading',
    },
    {
      title: 'Consumption',
      key: 'consumption',
      dataIndex: 'consumption',
      render: (val: any) => Math.round(val * 100) / 100,
    },
    {
      title: 'Bill',
      key: 'total_balance',
      render: (item: any) => Math.round(item?.total_balance * 100) / 100,
    },
    {
      title: 'Total Paid Amount',
      key: 'total_balance',
      render: (item: any) => Math.round(item?.payments * 100) / 100,
    },
    {
      title: 'Status',
      render: (item: any) =>
        Math.round(item.total_balance) <= Math.round(item.payments)
          ? 'Paid'
          : 'Unpaid',
    },
    {
      title: 'Action',
      key: 'action',
      render: (record: any) => (
        <Space>
          <Tooltip title="Show Bill">
            <Button
              type="link"
              icon={<ContainerOutlined />}
              size="middle"
              style={{ width: 50 }}
              onClick={() => {
                getGraphData({
                  variables: {
                    id: record?.id,
                  },
                });
                payments_form.setFieldsValue({ billing_id: record.billing_id });
                setInfo(record);
                setShowReceipt(true);
              }}
            />
          </Tooltip>
          <Tooltip title="Update current reading">
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => {
                setShowCurrentReading(true);
                reading_form.setFieldsValue({
                  current_reading: record.current_reading,
                  id: record.billing_id,
                  current_reading_backup: record.current_reading,
                });
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];
  // METER READING TABLE //
  const subMeterColumn = [
    {
      title: 'Reading ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
    },
    {
      title: 'Date',
      dateIndex: 'date_time',
      key: 'date_time',
      render: (_: any, record: { key: React.Key; date_time: any }) => {
        return moment(record.date_time).format('LLL');
      },
    },
  ];
  // ADDITIONAL FEES COLUMN //
  const subFeecolumns = [
    {
      title: 'Title',
      key: 'title',
      render: (item: any) => {
        return <p>{item.additional_fee?.title}</p>;
      },
    },
    {
      title: 'Type',
      key: 'type',
      render: (item: any) => (
        <Tag color="green">{item?.additional_fee?.mode_of_payment}</Tag>
      ),
    },
    {
      title: 'Amount',
      key: 'amount',
      render: (item: any) => {
        return item.additional_fee.amount;
      },
    },
    {
      title: 'Status',
      key: 'status',
      render: (item: any) => <Tag color="green">{item?.payment_status}</Tag>,
    },
    {
      title: 'No. of Bills',
      render: ({ paid, count }: any) => (
        <Tag>{`${paid.aggregate.count} / ${count.aggregate.count}`}</Tag>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (item: any) => (
        <Space>
          <Tooltip title="Delete Fee">
            <Button
              type="link"
              icon={<DeleteOutlined />}
              size="middle"
              style={{ width: 50 }}
              onClick={() => _onDeleteAdditionalFees(item)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const [showCurrentReading, setShowCurrentReading] = useState<boolean>(false);
  const [user, setUser] = useState<any>(null);
  const [meters, setMeters] = useState<Meter[]>([]);
  const [info, setInfo] = useState(null);
  const [showReceipt, setShowReceipt] = useState<boolean>(false);
  const [paymentsTable, setPaymentsTable] = useState<Array<any>>([]);
  const [meterReadingTable, setMeterReadingTable] = useState<Array<any>>([]);
  const [showQR, setShowQR] = useState(false);
  const [meter_id, setMeterId] = useState<string>('');
  const [showForm, setShowForm] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [showMeter, setShowMeter] = useState<boolean>(true);
  const [userBill, setUserBill] = useState<any>(null);
  const [userPayments, setUserPayments] = useState<any>(null);
  const [userMeterReading, setUserMeterReading] = useState<any>(null);
  const [barangays, setBarangays] = useState([]);
  const [billsTable, setBillsTable] = useState<Array<any>>([]);
  const [rates, setRates] = useState<Rate[]>([]);
  const [addFees, setAddFees] = useState<AdditionalFees[]>([]);
  const [notesTable, setNotesTable] = useState<Array<any>>([]);
  const [showFees, setShowFees] = useState<boolean>(false);
  const [addFeesTable, setAddFeesTable] = useState<Array<any>>([]);
  const [graphDatas, setGraphDatas] = useState({ labels: [], data: [] });
  const [settingsInfo, setSettingsInfo] = useState<any>();
  const [municipalities, setMunicipalities] = useState<
    { label: string; value: string; barangays: object }[]
  >([]);

  const [getGraphData, { data: graph_data }] = useLazyQuery(
    queries.GET_GRAPH_BILL_DATA,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const [getCheckMeter, { data: meter_check }] = useLazyQuery(
    queries.GET_METER,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const { data: settings_data } = useQuery(queries.GET_SETTINGS, {
    fetchPolicy: 'no-cache',
  });
  const { data: subscriber, refetch } = useQuery(queries.GET_CUSTOMER, {
    variables: {
      where: {
        id: {
          _eq: parseInt(params.id),
        },
      },
    },
    fetchPolicy: 'no-cache',
  });
  const { data: addFees_data } = useQuery(queries.GET_ADDFEES, {
    fetchPolicy: 'no-cache',
  });
  const { data: meter_data } = useSubscription(subscriptions.GET_METERS, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        is_taken: { _eq: false },
      },
    },
  });

  const [deleteSubscriberAddFee] = useMutation(
    mutations.DELETE_SUBSCRIBERS_ADDITIONAL_FEE,
    {
      onCompleted(res) {
        message.success('Additional fee deleted successfully.');
        refetch();
      },
      onError({ graphQLErrors }) {
        console.log(graphQLErrors);
        message.error(graphQLErrors[0].message);
      },
    }
  );
  const [insertPayments] = useMutation<any>(mutations.INSERT_PAYMENTS, {
    onCompleted(res) {
      message.success({ content: 'Payment has been received successfully ' });
      refetch();
      form.resetFields();
    },
    onError({ graphQLErrors }) {
      message.error('Something is wrong.');
    },
  });
  const [insertNotes] = useMutation(mutations.CREATE_NOTE, {
    onCompleted(res) {
      message.success('Create/Insert Successful');
      notesForm.resetFields();
      refetch();
    },
    onError(e) {
      console.log(e, 'lakjsdflasdjf');
    },
  });
  const [insertFee] = useMutation(mutations.INSERT_SUBSCRIBERS_FEE, {
    onCompleted(res) {
      additional_fee_form.setFieldsValue({
        additional_fees_id: null,
        notes: null,
      });
      message.success('Successful');
      setShowFees(false);
      refetch();
    },
  });
  const [updateSubscriber] = useMutation(mutations.INSERT_UPDATE_CUSTOMER, {
    onCompleted(res) {
      message.success('Success');
      refetch();
    },
    onError({ graphQLErrors }) {
      message.error('Update failed.');
    },
  });

  const [updateCurrentReading] = useMutation(mutations.UPDATE_CURRENT_READING, {
    onCompleted(res) {
      console.log(res);
      refetch();
      setShowCurrentReading(false);
      message.success('Update Successful');
    },
    onError({ graphQLErrors }) {
      console.log(graphQLErrors);
    },
  });

  const _onFinish = (data: any) => {
    updateSubscriber({
      variables: {
        data,
      },
    });
  };

  const _finishReading = (data: any) => {
    updateCurrentReading({
      variables: {
        ...data,
      },
    });
  };

  const _onFinishScan = (meter_id: string) => {
    if (meter_id) {
      setMeterId(meter_id);
      checkMeterID(meter_id);
      setShowQR(false);
    }
  };

  const _showHandler = (status?: Boolean) => {
    if (status) {
      setShowForm(true);
      form.resetFields();
    } else {
      setShowForm(false);
    }
    setShow(true);
    // form.resetFields()
  };

  const _closeHandler = () => {
    setShow(false);
    setShowForm(false);
    setShowMeter(true);
    form.resetFields();
  };

  const checkMeterID = (value: any) => {
    getCheckMeter({
      variables: {
        where: {
          serial_number: {
            _eq: value,
          },
        },
      },
    });
  };

  const setBill = () => {
    if (userBill) {
      const result = subscriber.subscribers.filter(
        ({ id }: any) => userBill.id === id
      );

      console.log(result?.[0]?.meter?.meter_readings, 'bill');
      setBillsTable(result?.[0]?.actual_bills);
      setMeterReadingTable(result?.[0]?.meter?.meter_readings);
      setShowReceipt(false);
    }
  };

  useEffect(() => {
    setBill();
  }, [userBill]);

  useEffect(() => {
    if (settings_data) {
      const data = settings_data.settings;
      setSettingsInfo(data);
    }
  }, [settings_data]);

  const _barangaysSetter = () => {
    if (municipalities.length > 0) {
      if (form.getFieldValue('municipality')) {
        const data: any = municipalities.filter(
          ({ value }) =>
            value.toLowerCase() ===
            form.getFieldValue('municipality').toLowerCase()
        );
        let barangays = data?.[0]?.barangays?.map((barangay: string) => ({
          label: barangay,
          value: barangay,
        }));
        setBarangays(barangays);
      }
    }
  };

  const _showProfile = (data: any) => {
    additional_fee_form.resetFields();
    additional_fee_form.setFieldsValue({ subscriber_id: data.id });
    payments_form.resetFields();
    payments_form.setFieldsValue({
      subscriber_id: data.id,
      billing_id: data.billing_id,
      id: data.billing_id,
    });
    notesForm.setFieldsValue({ subscriber_id: data.id });
    setUser(data);
    setUserBill(data);
    setUserPayments(data);
    setUserMeterReading(data);
    setShowMeter(false);
    form.setFieldsValue(data);
    _showHandler(false);
    _barangaysSetter();
  };

  const setPay = () => {
    if (userPayments) {
      const result = subscriber.subscribers.filter(
        ({ id }: any) => userPayments.id === id
      );
      setPaymentsTable(result?.[0]?.payments);
    }
  };

  const _onFinishNotes = (data: any) => {
    insertNotes({
      variables: {
        data: {
          ...data,
          subscriber_id: user.id,
        },
      },
    });
  };

  const setNotes = () => {
    if (user) {
      const result = subscriber.subscribers.filter(
        ({ id }: any) => user.id === id
      );
      setNotesTable(result?.[0]?.subscriber_notes);
    }
  };

  const setFees = () => {
    if (user) {
      const result = subscriber.subscribers.filter(
        ({ id }: any) => user.id === id
      );
      setAddFeesTable(result?.[0]?.subscribers_additional_fees);
    }
  };

  useEffect(() => {
    setPay();
  }, [userPayments]);

  useEffect(() => {
    if (addFees_data) {
      const items = addFees_data.additional_fees.map((id: AdditionalFees) => {
        return {
          ...id,
        };
      });
      setAddFees(items);
    }
  }, [addFees_data]);

  const getKeyValue =
    <T extends object, U extends keyof T>(obj: T) =>
    (key: U) =>
      obj[key];

  useEffect(() => {
    if (settings_data) {
      const {
        region,
        province,
      }: { region: keyof object; province: keyof object } =
        settings_data.settings[0];
      const result: object = getKeyValue(philippines)(region).province_list;
      const municipalities: any = getKeyValue(result)(province);
      const data: { label: string; value: string; barangays: object }[] =
        Object.keys(municipalities.municipality_list).map(key => ({
          label: key,
          value: key,
          barangays: municipalities.municipality_list[key].barangay_list,
        }));
      setMunicipalities(data);
    }
  }, [settings_data]);

  useEffect(() => {
    if (subscriber) {
      _showProfile(subscriber.subscribers[0]);
    }
  }, [subscriber]);

  useEffect(() => {
    if (meter_data) {
      const items = meter_data.meters.map(({ id, serial_number }: Meter) => {
        return {
          id,
          serial_number,
        };
      });
      setMeters(items);
    }
  }, [meter_data]);

  useEffect(() => {
    if (graph_data) {
      setGraphDatas({
        labels: graph_data.billings.map(({ billing_date }: any) =>
          moment(billing_date).format('MMM')
        ),
        data: graph_data.billings.map(
          ({ total_balance }: any) => Math.round(total_balance * 100) / 100
        ),
      });
    }
  }, [graph_data]);

  useEffect(() => {
    setFees();
    setNotes();
  }, [user]);

  const _onFinishFees = (data: any) => {
    insertFee({
      variables: {
        data,
      },
    });
  };

  const _additionalFeesSubmit = () => {
    additional_fee_form.submit();
  };

  const _onDeleteAdditionalFees = (data: any) => {
    confirm({
      title: `Are you sure you want to delete this fee?`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        deleteSubscriberAddFee({
          variables: {
            id: data.id,
          },
        });
      },
    });
  };

  const handleCancel = () => {
    setShowReceipt(false);
  };

  const _onFinishPay = (data: any) => {
    confirm({
      title: (
        <span>
          <span>A payment of </span>
          <span className="font-bold">Php {data.amount} </span>
          <span>has been received. Please confirm to proceed.</span>
        </span>
      ),
      icon: <ExclamationCircleOutlined />,
      onOk() {
        insertPayments({
          variables: {
            data: {
              amount: data.amount,
              billing_id: data.billing_id,
              subscribers_id: data.subscriber_id,
              // id: data.id,
              date: moment().format(),
              transacted_by: auth.getUserInfo().id,
            },
          },
        });
      },
    });
  };

  const subs_id = (id: number): String => {
    var sub_id = `${id}`;
    while (sub_id.length < 7) {
      sub_id = `0${sub_id}`;
    }
    return sub_id;
  };

  const graphData = {
    labels: graphDatas.labels,
    datasets: [
      {
        label: 'Monthly Bills',
        data: graphDatas.data,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(54, 162, 235, 0.2)',
        ],
      },
    ],
  };

  const value = {
    _onFinish,
    _onFinishPay,
    subs_id,
    rates,
    addFees,
    form,
    user,
    meters,
    meter_id,
    showForm,
    show,
    showMeter,
    userMeterReading,
    subPayColumn,
    subBillColumn,
    billsTable,
    paymentsTable,
    subMeterColumn,
    meterReadingTable,
    setShowQR,
    showQR,
    showReceipt,
    barangays,
    info,
    _onFinishScan,
    _showHandler,
    _closeHandler,
    _onFinishNotes,
    setShowFees,
    showFees,
    addFeesTable,
    notesTable,
    additional_fee_form,
    _onFinishFees,
    _additionalFeesSubmit,
    subFeecolumns,
    notesForm,
    payments_form,
    handleCancel,
    graphDatas,
    graphData,
    settingsInfo,
    showCurrentReading,
    setShowCurrentReading,
    reading_form,
    _finishReading,
  };
  return (
    <PageContext.Provider value={value}>
      <SubscriberView />
    </PageContext.Provider>
  );
};

export default SubscriberController;
