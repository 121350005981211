import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Login from '../pages/auth/Login.tsx';
import BillingsStore from '../pages/dashboard/billings/billings-store';
import CustomersStore from '../pages/dashboard/customers/customers-store';
import DashboardStore from '../pages/dashboard/dashboard/dashboard-store';
import history from '../utils/history';
import { AuthGuard, DashGuard } from './modules.js';
import AuthVerifyComponent from '../utils/check';
import CustomerController from '../pages/dashboard/customers';
import DashboardController from '../pages/dashboard/dashboard';
import MeterController from '../pages/dashboard/meters';
import RatesController from '../pages/dashboard/rates';
import PaymentsController from '../pages/dashboard/payments';
import SettingsController from '../pages/dashboard/settings';
import RolesController from '../pages/dashboard/users-role/roles';
import UsersController from '../pages/dashboard/users-role/users';
import BillingsController from '../pages/dashboard/billings';
import PaymentsViewController from '../pages/dashboard/payments-view';
import AdditionalFeesController from '../pages/dashboard/additional-fees';
import auth from '../lib/services';
import Logout from '../pages/dashboard/logout';
import FeedController from '../pages/dashboard/feeds';
import SubscriberController from '../pages/dashboard/subscriber';
import InjectorController from '../pages/dashboard/injector';
import CategoryController from '../pages/dashboard/categories';
import ReportController from '../pages/dashboard/reports';
import UserController from '../pages/dashboard/users';

export default function App() {
  const role = auth.getRole();
  return (
    <Router history={history}>
      <Switch>
        <AuthGuard exact path="/" component={Login} />
        {/* <DashGuard exact path="/dashboard" component={DashboardController} /> */}
        <DashGuard exact path="/reports" component={ReportController} />
        <DashGuard exact path="/feeds" component={FeedController} />
        <DashGuard exact path="/categories" component={CategoryController} />
        <DashGuard exact path="/users" component={UserController} />
        <DashGuard exact path="/logout" component={Logout} />
      </Switch>
      <AuthVerifyComponent />
    </Router>
  );
}
