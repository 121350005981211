import { useMutation, useSubscription } from '@apollo/client';
import { Button, Drawer, List } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { PageContext } from '../../../lib/context';
import mutations from '../../../utils/gql/mutations';
import { subscriptions } from '../../../utils/gql/subscriptions';

const ReportMessages = ({ width, title }: any) => {
  const { report, showMessage, _showHandler, _closeMessages } =
    useContext(PageContext);
  const [messages, setMessages] = useState<any>([]);
  const [newMessage, setNewMessage] = useState('');

  const submit = (e:any) => {
    e.preventDefault();
    e.target.reset();
    INSERT_MESSAGE({
      variables:{
        data:{
          message: newMessage,
          report_id: report.id,
          sender_type: 'admin',
          sender_id : 1,
          user_id : 1,
          mobile_number_id : report.mobile_number_id
        },
      }
    })
    setNewMessage('')
  };

  const { data } = useSubscription(subscriptions.GET_MESSAGES, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        report_id: { _eq: report.id },
      },
    },
  });

  const [INSERT_MESSAGE] = useMutation(mutations.INSERT_MESSAGE, {
    onCompleted(res) {
    },
    onError({ graphQLErrors }) {
      console.log(graphQLErrors[0].message)
    },
  });

  useEffect(() => {
    if (data) {
      setMessages(data.data);
      console.log(data.data, 'data');
    }
  }, [data]);

  return (
    <>
      <Drawer
        visible={showMessage}
        width={width}
        closable={true}
        onClose={_closeMessages}
        title={report.title+ ' messages'}
        // footer={
        //   <div
        //     style={{
        //       textAlign: 'right',
        //     }}
        //   >
        //     <Button
        //       onClick={_closeMessages.bind(this)}
        //       style={{ marginRight: 8 }}
        //     >
        //       Cancel
        //     </Button>
        //     <Button onClick={submit} type="primary">
        //       Submit
        //     </Button>
        //   </div>
        // }
      >
        <List
          style={{ height: 'calc(100vh - 256px)', overflow: 'auto' }}
          dataSource={messages}
          renderItem={(message: any) => (
            // <List.Item key={message.id} style={{ textAlign: message.sender_type === 'admin' ? 'right' : 'left' }}>
            //   <div>{message.mobile_number_id}</div>
            //   <div>{message.message}</div>
            // </List.Item>
            <div
              className="flex items-center justify-start mb-4"
              key={message.id}
              style={{
                justifyContent:
                  message.sender_type == 'admin' ? 'flex-end' : 'flex-start',
              }}
            >
              <div className="border border-gray-300 rounded-lg p-4">
                <div className="font-semibold mb-2 capitalize">
                  {message.sender_type}
                </div>
                <div>{message.message}</div>
              </div>
            </div>
          )}
        />
          <form onSubmit={submit}>
        <div className="flex items-center">
            <input
              required
              onChange={ (event) => setNewMessage(event.target.value) } 
              type="text"
              placeholder="Type a message..."
              className="border border-gray-300 rounded-l-lg py-2 px-4 w-full"
            />
            <button type='submit' 
              className="bg-blue-500 text-white font-semibold py-2 px-4 rounded-r-lg" 
            >
              Send
            </button>
        </div>
            </form>
      </Drawer>
    </>
  );
};

export default ReportMessages;
