import { Col, Form, Input, InputNumber, Row } from 'antd';
import { useContext } from 'react';
import { PageContext } from '../../../lib/context';

const CategoryForm = () => {
  const { form, _onFinish } = useContext(PageContext);

  return (
    <Form layout="vertical" form={form} onFinish={_onFinish}>
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item name="id" hidden={true}>
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Name is required' }]}
          >
            {/* <Input /> */}
            <Input />
          </Form.Item>
        </Col>
        <Col span={19}>
          <Form.Item
            label="Selected Color"
            name="color"
          >
            <Input readOnly></Input>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item
            label="Picker"
            name="color"
            rules={[{ required: true, message: 'Hex color is required' }]}
          >
            <Input type='color' />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default CategoryForm;
