import gql from 'graphql-tag';

const mutations = {
  INSERT_UPDATE_METER: gql`
    mutation create_meter($data: [meters_insert_input!]!) {
      insert_meters(
        objects: $data
        on_conflict: {
          constraint: meters_id_key
          update_columns: [serial_number, model, brand, latitude, longitude]
        }
      ) {
        returning {
          id
        }
      }
    }
  `,
  INSERT_RATE: gql`
    mutation insert_rate($data: [rates_insert_input!]!) {
      insert_rates(objects: $data) {
        returning {
          id
        }
      }
    }
  `,
  UPDATE_RATE: gql`
    mutation update_rates(
      $data: [rates_insert_input!]!
      $lists: [rates_list_insert_input!]!
      $id: Int!
    ) {
      insert_rates(
        objects: $data
        on_conflict: {
          constraint: rates_pkey
          update_columns: [name, rate_type]
        }
      ) {
        returning {
          id
        }
      }

      delete_rates_list(where: { rate_id: { _eq: $id } }) {
        returning {
          id
        }
      }

      insert_rates_list(objects: $lists) {
        returning {
          id
        }
      }
    }
  `,
  INSERT_PAYMENT: gql`
    mutation newpaymen(
      $billId: Int!
      $billUPdate: billings_set_input
      $payment: [payments_insert_input!]!
    ) {
      update_billings_by_pk(pk_columns: { id: $billId }, _set: $billUPdate) {
        id
      }
      insert_payments(objects: $payment) {
        affected_rows
      }
    }
  `,

  INSERT_UPDATE_CUSTOMER: gql`
    mutation insert_subscriber($data: [subscribers_insert_input!]!) {
      insert_subscribers(
        objects: $data
        on_conflict: {
          constraint: subscribers_id_key
          update_columns: [
            first_name
            last_name
            middle_name
            mobile_number
            barangay
            municipality
            complete_address
            zip_code
            meter_id
            rates_id
            is_active
          ]
        }
      ) {
        returning {
          id
        }
      }
    }
  `,

  // UPDATE_ADDITIONAL_FEES: gql`
  //   mutation update_subscriber(
  //     $id: Int
  //     $data: subscribers_set_input
  //     $add: [additional_fees_insert_input!]!
  //   ) {
  //     update_subscribers(where: { id: { _eq: $id } }, _set: $data) {
  //       returning {
  //         id
  //       }
  //     }
  //     delete_additional_fees(where: { subscriber_id: { _eq: $id } }) {
  //       returning {
  //         id
  //       }
  //     }
  //     insert_additional_fees(objects: $add) {
  //       returning {
  //         id
  //       }
  //     }
  //   }
  // `,

  UPDATE_SUBSCRIBER_ADDITIONAL_FEES: gql`
    mutation MyMutation($add: [subscribers_additional_fees_insert_input!]!) {
      insert_subscribers_additional_fees(objects: $add) {
        returning {
          id
        }
      }
    }
  `,

  UPDATE_USERS: gql`
    mutation MyMutation($id: Int!, $data: users_set_input) {
      update_users(where: { id: { _eq: $id } }, _set: $data) {
        returning {
          id
        }
      }
    }
  `,

  UPDATE_SETTINGS: gql`
    mutation update_settings($id: Int, $data: settings_set_input) {
      update_settings(where: { id: { _eq: $id } }, _set: $data) {
        affected_rows
        returning {
          id
          sending_to_customers
          disconnection
          deadline
          cutoff
          created_at
          updated_at
        }
      }
    }
  `,

  INSERT_PAYMENTS: gql`
    mutation insert_payments($data: [payments_insert_input!]!) {
      insert_payments(objects: $data) {
        returning {
          id
        }
      }
    }
  `,

  INSERT_UPDATE_ADDITIONAL_FEES: gql`
    mutation insert_additional_fees($data: [additional_fees_insert_input!]!) {
      insert_additional_fees(
        objects: $data
        on_conflict: {
          constraint: additional_fees_pkey
          update_columns: [title, mode_of_payment, number_of_months, amount]
        }
      ) {
        returning {
          id
        }
      }
    }
  `,
  INSERT_SUBSCRIBERS_FEE: gql`
    mutation create_fees($data: [subscribers_additional_fees_insert_input!]!) {
      insert_subscribers_additional_fees(objects: $data) {
        returning {
          id
        }
      }
    }
  `,

  DELETE_SUBSCRIBERS_ADDITIONAL_FEE: gql`
    mutation delete_additional_fees($id: Int!) {
      delete_subscribers_additional_fees(where: { id: { _eq: $id } }) {
        affected_rows
      }
    }
  `,

  CREATE_NOTE: gql`
    mutation create_update_notes($data: [subscriber_notes_insert_input!]!) {
      insert_subscriber_notes(
        objects: $data
        on_conflict: {
          constraint: subscriber_notes_pkey
          update_columns: [notes]
        }
      ) {
        returning {
          id
        }
      }
    }
  `,
  RELEASE_METER: gql`
    mutation release_meter($id: Int!) {
      update_meters(where: { id: { _eq: $id } }, _set: { is_taken: false }) {
        returning {
          id
        }
      }
    }
  `,
  DELETE_SUBSCRIBER: gql`
    mutation release_meter($id: Int!) {
      delete_subscribers(where: { id: { _eq: $id } }) {
        returning {
          id
        }
      }
    }
  `,
  CREATE_ROLES: gql`
    mutation insert_role($data: [roles_insert_input!]!) {
      insert_roles(
        objects: $data
        on_conflict: {
          constraint: roles_name_key
          update_columns: [
            dashboard
            customers
            billings
            meters
            rates
            payments
            additional_fees
            settings
          ]
        }
      ) {
        returning {
          id
        }
      }
    }
  `,
  CREATE_READING: gql`
    mutation insert_reading($meter_id: String!, $value: numeric!) {
      insert_meter_readings(objects: { meter_id: $meter_id, value: $value }) {
        returning {
          id
          value
          meter_id
        }
      }
    }
  `,
  UPDATE_VALVE: gql`
    mutation update_valve_status($meter_id: String!, $status: String!) {
      UpdateValveStatus(meter_id: $meter_id, status: $status) {
        errcode
        errmsg
        valueId
      }
    }
  `,
  DELETE_PAYMENT: gql`
    mutation delete_payment($id: Int!) {
      delete_payments(where: { id: { _eq: $id } }) {
        returning {
          id
        }
      }
    }
  `,
  UPDATE_CURRENT_READING: gql`
    mutation update_current_reading(
      $id: Int!
      $current_reading: numeric!
      $current_reading_backup: numeric!
    ) {
      update_billings(
        where: { id: { _eq: $id } }
        _set: {
          current_reading: $current_reading
          current_reading_backup: $current_reading_backup
        }
      ) {
        returning {
          id
        }
      }
    }
  `,

  // for categories mutation

  DELETE_CATEGORIES: gql`
    mutation remove_category($id: bigint!) {
      delete_mandaue_categories(where: { id: { _eq: $id } }) {
        affected_rows
      }
    }
  `,

  INSERT_UPDATE_CATEGORY: gql`
    mutation ($data: [mandaue_categories_insert_input!]!) {
      insert_mandaue_categories(
        objects: $data
        on_conflict: {
          constraint: categories_pkey
          update_columns: [name, color]
        }
      ) {
        returning {
          id
        }
      }
    }
  `,

  // for feeds mutation

  DELETE_FEED: gql`
    mutation remove_feed($id: Int!) {
      delete_mandaue_feeds(where: { id: { _eq: $id } }) {
        affected_rows
      }
    }
  `,

  INSERT_UPDATE_FEED: gql`
    mutation ($data: [mandaue_feeds_insert_input!]!) {
      insert_mandaue_feeds(
        objects: $data
        on_conflict: {
          constraint: feeds_pkey
          update_columns: [description, category_id, is_public, image]
        }
      ) {
        returning {
          id
        }
      }
    }
  `,

  // for reports mutations
  INSERT_UPDATE_REPORT: gql`
    mutation ($data: [mandaue_reports_insert_input!]!) {
      insert_mandaue_reports(
        objects: $data
        on_conflict: {
          constraint: reports_pkey
          update_columns: [
            title
            description
            location
            status
            mobile_number_id
            category_id
          ]
        }
      ) {
        returning {
          id
        }
      }
    }
  `,
  UPDATE_REPORTS_IS_OPEN: gql`
    mutation update_reports_is_open($id: Int!) {
      update_mandaue_reports(
        _set: { is_open: true }
        where: { id: { _eq: $id } }
      ) {
        affected_rows
      }
    }
  `,
  //  for messages
  INSERT_MESSAGE: gql`
    mutation ($data: [mandaue_messages_insert_input!]!) {
      insert_mandaue_messages(objects: $data) {
        affected_rows
      }
    }
  `,
};

export default mutations;
