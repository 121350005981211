import { useHistory } from 'react-router';

const Logout = () => {
  const history = useHistory();
  localStorage.clear();
  history.push('/');
  window.location.reload();
};

export default Logout;
