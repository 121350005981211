import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { PageContext } from "../../../lib/context";
import queries from "../../../utils/gql/queries";
import UsersView from "./users";

export default function UserController() {
  const [users, setUsers] = useState([]);

  interface Sort {
    id: string | null | boolean;
    mobile_number: any;
    reports_count: any;
  }
  const [sortOrder, setSortOrder] = useState<Sort>({
    id: false,
    mobile_number: false,
    reports_count: false,
  });

  const [getMobileNumbers, { data }] = useLazyQuery(queries.GET_MOBILE_NUMBERS, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    getMobileNumbers();
  }, [getMobileNumbers]);

  useEffect(() => {
    if (data) {
      setUsers(data.data);
    }
  }, [data]);

  const usersColumns = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      sortOrder: sortOrder.id,
    },
    {
      title: 'Mobile Number',
      key: 'mobile_number',
      dataIndex: 'mobile_number',
      sortOrder: sortOrder.mobile_number,
    },
    {
      title: 'Name',
      key: 'name',
      render: (val: any) => `${val?.first_name ?? ""} ${val.last_name ?? ""}`
    },
    {
      title: 'Reports Count',
      dataIndex: 'reports_aggregate',
      key: 'reports_aggregate',
      render: (reports_aggregate: any) => (
        <span>{reports_aggregate.aggregate.count}</span>
      )
    }
  ];

  const value: object = {
    datas: users,
    usersColumns
  }
  return (
    <PageContext.Provider value={value}>
      <UsersView />
    </PageContext.Provider>
  )
}