import gql from 'graphql-tag';

const queries = {
  GET_TOTAL_PAYMENTS: gql`
    query payments_total {
      payments_aggregate {
        aggregate {
          count(columns: id)
        }
      }
    }
  `,
  GET_METER_COUNT: gql`
    query meter_count {
      view_meters_aggregate(where: { injector: { _eq: true } }) {
        aggregate {
          count
        }
      }
    }
  `,
  GET_BILLINGS_TOTAL: gql`
    query billings_total_count {
      view_billing_csv_aggregate {
        aggregate {
          count(columns: id)
        }
      }
    }
  `,
  GET_CUSTOMER_TOTAL: gql`
    query customer_count {
      subscribers_aggregate {
        aggregate {
          count(columns: id)
        }
      }
    }
  `,
  GET_BILLING_CSV: gql`
    query billing_csv {
      view_billing_csv {
        account_id
        additional_fee_amount
        additional_fee_title
        advance_payment
        name: concat
        consumer_type
        current_reading
        consumption
        cutoff_date
        id
        meter_id
        mobile_number
        previous_balance
        previous_reading
        tax
        total_balance
        water_fee
      }
    }
  `,
  GET_CUSTOMER: gql`
    query (
      $where: subscribers_bool_exp
      $order_by: [subscribers_order_by!]
      $limit: Int
      $offset: Int
    ) {
      subscribers(
        where: $where
        order_by: $order_by
        limit: $limit
        offset: $offset
      ) {
        id
        first_name
        last_name
        created_at
        consumer_type
        complete_address
        meter_id
        middle_name
        mobile_number
        municipality
        zip_code
        rates_id
        barangay
        is_active
        subscriber_notes(order_by: { id: desc }) {
          id
          notes
          subscriber_id
          updated_at
        }
        billings {
          subscriber {
            complete_address
            id
            first_name
            last_name
            meter {
              serial_number
              brand
              model
            }
            zip_code
            rate {
              name
            }
          }
          advance_payment
          previous_balance
          consumption
          fees
          payments_aggregate {
            aggregate {
              sum {
                amount
              }
            }
          }
          billing_date
          current_reading
          current_reading_date
          id
          water_fee
          taxes
          previous_reading
          previous_reading_date
          total_balance
          additional_bills {
            amount
            additional_fee_information {
              title
            }
          }
        }
        total_bill: billings_aggregate {
          aggregate {
            sum {
              total_balance
            }
          }
        }
        total_payment: payments_aggregate {
          aggregate {
            sum {
              amount
            }
          }
        }
        subscribers_additional_fees {
          id
          payment_status
          additional_fee {
            id
            mode_of_payment
            number_of_months
            amount
            title
          }
          paid: actual_bills_aggregate(where: { is_paid: { _eq: true } }) {
            aggregate {
              count
            }
          }
          count: actual_bills_aggregate {
            aggregate {
              count
            }
          }
        }
        payments(order_by: { id: desc }) {
          amount
          id
          date
          billing_id
          subscribers_id
          bill {
            total_balance
          }
        }
        meter {
          id
          serial_number
          meter_readings(order_by: { id: desc }) {
            id
            value
            date_time
          }
        }
        latest_payments {
          balance
        }
        actual_bills(order_by: { billing_id: desc }) {
          id
          billing_id
          billing_date
          current_reading
          previous_reading
          current_reading_backup
          consumption
          total_balance
          payments
          name
          complete_address
          water_fee
          previous_balance
          advance_payment
          additional_bills {
            amount
            additional_fee_information {
              title
            }
          }
          subscriber {
            complete_address
            id
            first_name
            last_name
            meter {
              serial_number
              brand
              model
            }
            zip_code
            rate {
              name
            }
          }
        }
      }
    }
  `,

  VIEW_CUSTOMER_WITH_BILLS: gql`
    query (
      $where: view_subscribers_bills_bool_exp
      $order_by: [view_subscribers_bills_order_by!]
      $limit: Int
      $offset: Int
    ) {
      subscribers: view_subscribers_bills(
        where: $where
        order_by: $order_by
        limit: $limit
        offset: $offset
      ) {
        id
        name
        serial_number
        complete_address
        barangay
        municipality
        balance
      }
    }
  `,

  GET_METER: gql`
    query (
      $where: view_meters_bool_exp
      $order_by: [view_meters_order_by!]
      $limit: Int
      $offset: Int
    ) {
      view_meters(
        where: $where
        order_by: $order_by
        limit: $limit
        offset: $offset
      ) {
        subscriber_id
        name
        id
        serial_number
        model
        updated_at
        brand
        created_at
        is_taken
        latitude
        longitude
        is_active
        reading_value
        meter_readings(order_by: { id: desc }) {
          battery
          updated_at
          meter_id
          value
          is_billed
          id
          date_time
          created_at
        }
        subscriber_info {
          first_name
          last_name
          complete_address
        }
      }
    }
  `,
  GET_RATE: gql`
    {
      rates {
        id
        name
        rate_type
        created_at
        lists {
          name
          minimum
          rate
          id
        }
      }
    }
  `,

  GET_USER: gql`
    query {
      users {
        id
        username
        full_name
        role_id
        role {
          name
        }
      }
    }
  `,

  GET_ROLES: gql`
    query {
      roles {
        id
        name
        dashboard
        customers
        billings
        meters
        rates
        payments
        additional_fees
        settings
        user_management
      }
    }
  `,

  GET_METER_READING: gql`
    query ($serial_id: String) {
      meter_readings(
        where: { meter: { serial_number: { _eq: $serial_id } } }
        order_by: { id: desc }
      ) {
        id
        date_time
        value
      }
    }
  `,

  BILLING_INFO: gql`
    query MyQuery($subs_id: Int) {
      billings(where: { subscriber_id: { _eq: $subs_id } }) {
        advance_payment
        billing_date
        billing_period
        created_at
        current_reading
        current_reading_date
        discount_on_advance_payment
        fees
        gross_current_bill
        id
        previous_balance
        previous_reading
        previous_reading_date
        subscriber_id
        taxes
        total_balance
        updated_at
        water_fee
        consumption
        payments_aggregate {
          aggregate {
            sum {
              amount
            }
          }
        }
      }
    }
  `,

  GET_CUSTOMER_BILLINGS: gql`
    query (
      $where: view_billing_csv_bool_exp
      $limit: Int
      $offset: Int
      $order_by: [view_billing_csv_order_by!]
    ) {
      view_billing_csv(
        where: $where
        limit: $limit
        offset: $offset
        order_by: $order_by
      ) {
        advance_payment
        consumption
        id
        previous_balance
        total_balance
        current_reading
        previous_reading
        water_fee
        complete_address
        name: concat
        account_id
        meter_id
        additional_fee_amount
        additional_fee_title
        consumer_type
        cutoff_date
        mobile_number
        tax
        payments {
          id
        }
        payments_aggregate {
          aggregate {
            sum {
              amount
            }
          }
        }
        subscriber {
          first_name
          last_name
          complete_address
          id
          zip_code
          meter {
            brand
            model
            serial_number
          }
          rate {
            name
          }
          subscribers_additional_fees {
            actual_bills {
              amount
              additional_fee {
                additional_fee {
                  title
                }
              }
            }
          }
        }
      }
    }
  `,
  //   query ($where: billings_bool_exp) {
  //     billings(where: $where, limit: 10) {
  //       advance_payment
  //       consumption
  //       fees
  //       id
  //       previous_balance
  //       total_balance
  //       payments_aggregate {
  //         aggregate {
  //           sum {
  //             amount
  //           }
  //         }
  //       }
  //       current_reading
  //       discount_on_advance_payment
  //       current_reading_date
  //       gross_current_bill
  //       billing_period
  //       billing_date
  //       previous_reading
  //       previous_reading_date
  //       water_fee
  //       taxes
  //       payments {
  //         id
  //       }
  //       additional_bills {
  //         amount
  //         additional_fee {
  //           additional_fee {
  //             title
  //           }
  //         }
  //       }
  //       subscriber {
  //         first_name
  //         last_name
  //         complete_address
  //         id
  //         zip_code
  //         meter {
  //           brand
  //           model
  //           serial_number
  //         }
  //         rate {
  //           name
  //         }
  //         subscribers_additional_fees {
  //           actual_bills {
  //             amount
  //             additional_fee {
  //               additional_fee {
  //                 title
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // `,

  GET_SETTINGS: gql`
    query MyQuery {
      settings {
        created_at
        cutoff
        deadline
        disconnection
        id
        sending_to_customers
        updated_at
        region
        province
        message
      }
    }
  `,

  GET_PAYMENTS: gql`
    query payments(
      $where: payments_bool_exp
      $order_by: [payments_order_by!]
      $offset: Int
      $limit: Int
    ) {
      payments(
        where: $where
        offset: $offset
        limit: $limit
        order_by: $order_by
      ) {
        amount
        billing_id
        date
        id
        subscribers_id
        transacted_by
        subscriber {
          first_name
          last_name
        }
        bill {
          total_balance
        }
        users {
          full_name
        }
      }
    }
  `,

  GET_CUSTOMER_RECORD: gql`
    query subscriber($id: Int!) {
      subscribers(where: { id: { _eq: $id } }) {
        id
        first_name
        last_name
        created_at
        consumer_type
        complete_address
        meter_id
        middle_name
        mobile_number
        municipality
        zip_code
        rates_id
        barangay
        is_active
        billings(order_by: { id: desc }) {
          id
          consumption
          billing_period
          created_at
          updated_at
          previous_balance
          previous_reading_date
          total_balance
          fees
          gross_current_bill
          discount_on_advance_payment
          billing_date
          advance_payment
          previous_reading
          current_reading
          current_reading_date
          subscriber_id
          water_fee
          taxes
          payments_aggregate {
            aggregate {
              sum {
                amount
              }
            }
          }
          subscriber {
            complete_address
            consumer_type
            first_name
            last_name
            zip_code
            rate {
              name
            }
            meter {
              serial_number
              brand
              model
            }
            subscribers_additional_fees {
              additional_fee {
                id
                mode_of_payment
                title
              }
            }
          }
        }
      }
    }
  `,
  GET_STATS: gql`
    query {
      subscribers: subscribers_aggregate {
        aggregate {
          count
        }
      }
      meters: meters_aggregate {
        aggregate {
          count
        }
      }
      unassigned: meters_aggregate(where: { is_taken: { _eq: false } }) {
        aggregate {
          count
        }
      }
      assigned: meters_aggregate(where: { is_taken: { _eq: true } }) {
        aggregate {
          count
        }
      }
    }
  `,
  GET_ADDFEES: gql`
    query MyQuery {
      additional_fees(order_by: { id: asc }) {
        id
        mode_of_payment
        number_of_months
        title
        amount
      }
    }
  `,

  GET_SUB_FEES_RECORD: gql`
    query MyQuery($sub_fee_id: Int) {
      subscribers_additional_fees(
        where: { additional_fee: { id: { _eq: $sub_fee_id } } }
      ) {
        id
        subscriber_id
        additional_fees_id
        additional_fee {
          mode_of_payment
          title
          amount
        }
      }
    }
  `,

  GET_DASHBOARD: gql`
    query subscribers_dashboard {
      subscribers {
        id
        first_name
        last_name
        middle_name
        complete_address
        is_active
      }
    }
  `,

  GET_GRAPH_BILL_DATA: gql`
    query latest_billings($id: Int!) {
      billings(
        where: { subscriber_id: { _eq: $id } }
        limit: 3
        order_by: { id: desc }
      ) {
        billing_date
        total_balance
      }
    }
  `,

  SEARCH_BILL: gql`
    query search_bill($where: billings_bool_exp) {
      billings(where: $where) {
        id
        subscriber_id
      }
    }
  `,

  MAPS: gql`
    query colors($where: view_map_bool_exp!) {
      view_map(where: $where) {
        id
        longitude
        latitude
        serial_number
        color
        meter_reading
        reading_date
        battery
        message
      }
    }
  `,
  GET_COLORS: gql`
    query ($where: view_map_csv_bool_exp!, $offset: Int, $limit: Int) {
      view_map_csv(
        where: $where
        order_by: { reading_date: desc }
        offset: $offset
        limit: $limit
      ) {
        id
        longitude
        latitude
        serial_number
        color
        meter_reading
        reading_date
        battery
        message
        name
        complete_address
      }
    }
  `,
  GET_COLOR_COUNT: gql`
    query {
      green: view_map_csv_aggregate(where: { color: { _eq: "green" } }) {
        aggregate {
          count
        }
      }
      black: view_map_csv_aggregate(where: { color: { _eq: "black" } }) {
        aggregate {
          count
        }
      }
      orange: view_map_csv_aggregate(where: { color: { _eq: "orange" } }) {
        aggregate {
          count
        }
      }
      grey: view_map_csv_aggregate(where: { color: { _eq: "grey" } }) {
        aggregate {
          count
        }
      }
      purple: view_map_csv_aggregate(where: { color: { _eq: "purple" } }) {
        aggregate {
          count
        }
      }
      no_location: view_map_csv_aggregate(
        where: { latitude: { _is_null: true } }
      ) {
        aggregate {
          count
        }
      }
    }
  `,
  GET_PAY_BILLS: gql`
    query pay_bills($id: Int) {
      billings(where: { id: { _eq: $id } }) {
        id
        total_balance
        subscriber {
          first_name
          last_name
        }
      }
    }
  `,

  // for reports queries

  GET_FEEDS: gql`
    query {
      data: mandaue_feeds(order_by: { id: desc }) {
        description
        id
        created_at
        category_id
        image
        is_public
        category {
          name
          color
        }
      }
    }
  `,

  // for categories query

  GET_CATEGORIES: gql`
    query {
      data: mandaue_categories(order_by: { id: desc }) {
        id
        name
        color
        created_at
      }
    }
  `,

  // for users/mobile_numbers query

  GET_MOBILE_NUMBERS: gql`
    query {
      data: mandaue_mobile_numbers(order_by: { id: desc }) {
        reports_aggregate {
          aggregate {
            count
          }
        }
        mobile_number
        first_name
        last_name
        id
      }
    }
  `,

  GET_REPORTS: gql`
    query ($where: mandaue_reports_bool_exp) {
      data: mandaue_reports(order_by: { id: desc }, where: $where) {
        id
        location
        description
        created_at
        title
        category_id
        mobile_number_id
        status
        is_open
        image
        category {
          color
          id
          name
        }
        mobile_number {
          id
          mobile_number
        }
      }
    }
  `,
};

export default queries;
