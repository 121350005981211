import { PageContext } from '../../../lib/context';
import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import queries from '../../../utils/gql/queries';
import { Button, Form, message, Modal, Space, Tag, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import mutations from '../../../utils/gql/mutations';
import CategoriesView from './categories';

export default function CategoryController() {
  interface Sort {
    id: string | null | boolean;
    name: any;
    color: any;
  }

  const [getCategories, { data }] = useLazyQuery(queries.GET_CATEGORIES, {
    fetchPolicy: 'no-cache',
  });

  const [deleteCategories] = useMutation(mutations.DELETE_CATEGORIES, {
    onCompleted(res) {
      message.success('Successful!');
      _getCategories();
    },
    onError() {
      message.error('Failed!');
    },
  });
  const [categories, setCategories] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [sort, setSort] = useState<any>(null);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [showMeter, setShowMeter] = useState<boolean>(true);
  const [sortOrder, setSortOrder] = useState<Sort>({
    id: false,
    name: false,
    color: false,
  });

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  useEffect(() => {
    if (data) {
      setCategories(data.data);
      console.log(data, 'effect');
      setRefreshing(false);
    }
  }, [data]);

  const handleSort = (pagination: any, filters: any, sorter: any) => {
    const sorterOrder: any = {
      ascend: 'asc',
      descend: 'desc',
    };
    if (sorter.columnKey === 'name') {
      setSort({
        name: sorterOrder[sorter.order],
      });
      setSortOrder({
        ...sortOrder,
        name: sorter.order,
      });
    } else if (sorter.columnKey === 'color') {
      setSort({
        color: sorterOrder[sorter.order],
      });
      setSortOrder({
        ...sortOrder,
        color: sorter.order,
      });
    } else {
      console.log(sorter.columnKey);
      setSort({
        [sorter.columnKey]: sorterOrder[sorter.order],
      });
      setSortOrder({ ...sortOrder, [sorter.columnKey]: sorter.order });
    }
  };

  useEffect(() => {
    _getCategories();
  }, [sort]);

  const _getCategories = () => {
    getCategories();
  };

  const { confirm } = Modal;
  const [form] = Form.useForm();

  const _submitHandler = () => {
    form.submit();
  };

  const _showEditHandler = (data: any) => {
    return () => {
      form.setFieldsValue(data);
      console.log(data, 'edit handler')
      _showHandler();
    };
  };

  const _showHandler = () => {
    setShowForm(true);
    setShow(true);
  };

  const _closeHandler = () => {
    setShow(false);
    setShowForm(false);
    setShowMeter(true);
    form.resetFields();
  };

  const _onFinish = (data: any) => {
    insertUpdateCategory({
      variables: {
        data,
      },
    });
    console.log(data, 'onfinish');
  };

  const [insertUpdateCategory] = useMutation(mutations.INSERT_UPDATE_CATEGORY, {
    onCompleted(res) {
      message.success('Success');
      _getCategories();
      _closeHandler();
    },
    onError({ graphQLErrors }) {
      console.log(graphQLErrors)
      message.error('Update failed.');
    },
  });

  const usersColumns = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      sortOrder: sortOrder.id,
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      sortOrder: sortOrder.name,
    },
    {
      title: 'Color',
      dataIndex: 'color',
      key: 'color',
      sortOrder: sortOrder.color,
      render: (color: any) => <Tag color={color}>{color}</Tag>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (record: any) => (
        <Space>
          <Tooltip title="Edit">
            <Button type="link" onClick={_showEditHandler(record)}>
              <EditOutlined />
            </Button>
          </Tooltip>
          <Tooltip title="Delete Subscriber">
            <Button
              type="link"
              size="middle"
              icon={<DeleteOutlined />}
              style={{ width: 50 }}
              onClick={() => {
                confirm({
                  title: 'This will delete the selected category.',
                  onOk() {
                    deleteCategories({
                      variables: {
                        id: record.id,
                      },
                    });
                  },
                });
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const value: object = {
    datas: categories,
    usersColumns,
    show,
    form,
    showForm,
    handleSort,
    _onFinish,
    _submitHandler,
    _showHandler,
    _closeHandler,
  };
  return (
    <PageContext.Provider value={value}>
      <CategoriesView />
    </PageContext.Provider>
  );
}
