import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Upload,
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { PageContext } from '../../../lib/context';
import TextArea from 'antd/lib/input/TextArea';
import { useLazyQuery } from '@apollo/client';
import queries from '../../../utils/gql/queries';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import type { UploadChangeParam } from 'antd/es/upload';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';

const FeedForm = () => {
  const { form, _onFinish, showForm } = useContext(PageContext);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();

  const [getCategories, { data }] = useLazyQuery(queries.GET_CATEGORIES, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if(showForm){
      console.log(form.getFieldValue('image'), 'test');
      setImageUrl(form.getFieldValue('image'));
    }
  }, [showForm]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  useEffect(() => {
    if (data) {
      setCategories(data.data);
    }
  }, [data]);

  const public_choices = [
    {
      value: true,
      text: 'YES',
    },
    {
      value: false,
      text: 'No',
    },
  ];

  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const handleChange: UploadProps['onChange'] = (info: any) => {
    getBase64(info.file.originFileObj as RcFile, url => {
      setLoading(false);
      setImageUrl(url);
    });
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Form layout="vertical" form={form} onFinish={_onFinish}>
      <Row gutter={24}>
        <Form.Item name="id" hidden={true}>
          <InputNumber />
        </Form.Item>
        <Form.Item name="image" hidden={true}>
          <InputNumber />
        </Form.Item>
        <Col span={24}>
          <Form.Item name="picture">
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader text-center mt-5"
              showUploadList={false}
              onChange={handleChange}
            >
              {imageUrl ? (
                <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
              ) : (
                uploadButton
              )}
            </Upload>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: 'Description is required' }]}
          >
            {/* <Input /> */}
            <TextArea />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Category"
            name="category_id"
            rules={[{ required: true, message: 'Category is required' }]}
          >
            <Select
              showSearch
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {categories.map(({ id, name }: any) => (
                <Select.Option key={`${id}`} value={id}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Public Feeds ?"
            name="is_public"
            rules={[{ required: true, message: 'Public Field is required' }]}
          >
            <Select
              showSearch
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {public_choices.map(({ value, text }: any) => (
                <Select.Option key={`${value}`} value={value}>
                  {text}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default FeedForm;
