import { EditOutlined, EyeOutlined, MessageOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, message, Space, Tag, Tooltip } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { PageContext } from '../../../lib/context';
import mutations from '../../../utils/gql/mutations';
import queries from '../../../utils/gql/queries';
import ReportMessages from './messages';
import ReportsView from './reports';

export default function ReportController() {
  const [reports, setReports] = useState([]);
  const [categories, setCategories] = useState([]);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [showMeter, setShowMeter] = useState<boolean>(true);
  const [image, setImage] = useState(null);

  interface Sort {
    id: string | null | boolean;
    title: any;
    description: any;
    location: any;
    category: any;
    mobile_number: any;
  }
  const [sortOrder, setSortOrder] = useState<Sort>({
    id: false,
    title: false,
    description: false,
    location: false,
    category: false,
    mobile_number: false,
  });

  const [getReports, { data, refetch }] = useLazyQuery(queries.GET_REPORTS, {
    fetchPolicy: 'no-cache',
  });

  const [getCategories, { data: category_data }] = useLazyQuery(
    queries.GET_CATEGORIES,
    {
      fetchPolicy: 'no-cache',
    }
  );

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  useEffect(() => {
    if (category_data) {
      setCategories(category_data.data);
    }
  }, [category_data]);

  useEffect(() => {
    getReports();
  }, [getReports]);

  useEffect(() => {
    if (data) {
      setReports(data.data);
    }
  }, [data]);

  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();

  const _showEditHandler = (data: any) => {
    return () => {
      form.setFieldsValue(data);
      setImage(data.image);
      _showHandler(data.id);
    };
  };

  const _showHandler = (id = 0) => {
    updateReportIsOpen({
      variables: {
        id: id,
      },
    });
    setShowForm(true);
    setShow(true);
  };

  const _closeHandler = () => {
    getReports();
    setShow(false);
    setShowForm(false);
    setShowMeter(true);
    form.resetFields();
  };

  const [insertUpdateReport] = useMutation(mutations.INSERT_UPDATE_REPORT, {
    onCompleted(res) {
      message.success('Success');
      getReports();
      _closeHandler();
    },
    onError({ graphQLErrors }) {
      console.log(graphQLErrors);
      message.error('Update failed.');
    },
  });

  const [updateReportIsOpen] = useMutation(mutations.UPDATE_REPORTS_IS_OPEN, {
    onCompleted(res) {},
    onError({ graphQLErrors }) {
      console.log(graphQLErrors);
      message.error('Update failed.');
    },
  });

  const _onFinish = (data: any) => {
    insertUpdateReport({
      variables: {
        data,
      },
    });
    console.log(data);
  };

  const _onFinishSearch = (data: any) => {
    console.log(data, 'data here');

    let where: any = {};

    console.log(data, 'finish search');
    if (data.title) {
      where = {
        _or: [
          {
            title: {
              _ilike: `%${data.title ?? null}%`,
            },
          },
        ],
      };
    }
    if (data.category_id) {
      where = {
        ...where,
        category_id: { _eq: data.category_id },
      };
    }
    if (data.status) {
      where = {
        ...where,
        status: { _eq: data.status },
      };
    }

    if (data.date) {
      where = {
        ...where,
        _and: [
          {
            created_at: {
              _gte: moment(data.date[0]),
            },
          },
          {
            created_at: {
              _lte: moment(data.date[1]),
            },
          },
        ],
      };
    }

    setReports([]);
    getReports({
      variables: {
        where,
      },
    });
  };

  const _submitHandler = () => {
    form.submit();
  };

  const statuses: any = [
    {
      id: 1,
      text: 'Pending',
      color: 'red',
    },
    {
      id: 2,
      text: 'Ongoing',
      color: 'blue',
    },
    {
      id: 3,
      text: 'Done',
      color: 'green',
    },
  ];

  const checkStatus = (id: number) => {
    return statuses.find(function (status: any) {
      return status.id === id;
    });
  };

  const usersColumns = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      sortOrder: sortOrder.id,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: any, record: any) => (
        <>
          <span className={`text-${checkStatus(status).color}-600 mr-2`}>
            {checkStatus(status).text}
          </span>
          {record.is_open ? '' : '(new)'}
        </>
      ),
    },
    {
      title: 'Title',
      key: 'title',
      dataIndex: 'title',
      sortOrder: sortOrder.title,
    },
    {
      title: 'Description',
      key: 'description',
      dataIndex: 'description',
      sortOrder: sortOrder.description,
    },
    {
      title: 'Location',
      key: 'location',
      dataIndex: 'location',
      sortOrder: sortOrder.location,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      render: (category: any) => (
        <div>
          {category ? (
            <Tag color={category.color} className="capitalize">
              {category.name}
            </Tag>
          ) : (
            '...'
          )}
        </div>
      ),
    },
    {
      title: 'From',
      dataIndex: 'mobile_number',
      key: 'mobile_number',
      render: (mobile_number: any) => (
        <span>{mobile_number ? mobile_number.mobile_number : '...'}</span>
      ),
    },
    {
      title: 'Date',
      key: 'date',
      render: (val: any) => moment(val.created_at).fromNow(),
    },
    {
      title: 'Action',
      key: 'action',
      render: (record: any) => (
        <Space>
          <Tooltip title="View Messages">
            <Button type="link" onClick={_showMessageHandler(record)}>
              <MessageOutlined />
            </Button>
          </Tooltip>
          <Tooltip title="View Report">
            <Button type="link" onClick={_showEditHandler(record)}>
              <EyeOutlined />
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ];

  //  reports messages

  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [report, setReport] = useState({
    title: '',
  });

  const _showMessageHandler = (data: any) => {
    return () => {
      setShowMessage(true);
      setReport(data);
    };
  };

  const _closeMessages = () => {
    setShowMessage(false);
  };

  const value: object = {
    datas: reports,
    usersColumns,
    show,
    categories,
    form,
    searchForm,
    showForm,
    statuses,
    image,
    // _searchFormChangeHandler,
    _onFinishSearch,
    _onFinish,
    _submitHandler,
    _showHandler,
    _closeHandler,

    // for reports messsages
    showMessage,
    _closeMessages,
    report,
  };
  return (
    <PageContext.Provider value={value}>
      <ReportsView />
    </PageContext.Provider>
  );
}
