import waterdroplet from '../../assets/new-logo.png';
import illustration from '../../assets/login-illustration.jpg';
import { Form, Input, Button, message } from 'antd';
import { UserOutlined, KeyOutlined } from '@ant-design/icons';
import auth from '../../lib/services';

export default function Login() {
  const [form] = Form.useForm();
  const _onFinish = (data: object) => {
    auth
      .login(data)
      .then(async (res: any) => {
        if (res.status === 401) {
          message.error('Invalid username or password!');
          return;
        }
        const data = await res.json();
        auth.storeToken(data.token);
        window.location.href = '/users';
      })
      .catch((err: any) => {
        console.log(err, 'error');
      });
  };
  return (
    <div className="h-screen w-full flex flex-row">
      <div className="w-2/3 p-56 flex flex-col items-center justify-center">
        <img src={waterdroplet} className="h-100" alt="illustration" />
        <p className="text-4xl font-bold" style={{ color: '#233863' }}>
          Welcome to Emergency Alert System
        </p>
        <p className="text-base text-gray-400">
          {/* Manage your customers, billings and automatic meter reading. */}
        </p>
        <p className="text-base text-gray-400">
          Powered by Digital Transformation - DX
        </p>
      </div>
      <div className="items-center flex flex-col justify-center">
        <div className="flex flex-row items-center">
          <img src={waterdroplet} alt="water-logo" className="h-10 w-10" />
          <p className="text-2xl font-bold mt-5" style={{ color: '#233863' }}>
            Emergency Alert
          </p>
        </div>
        <div className="justify-start">
          <p className="text-base">Login to Emergency Alert Dashboard</p>
          <Form form={form} layout="vertical" onFinish={_onFinish}>
            <Form.Item name="username" label="Username">
              <Input
                placeholder="Username"
                prefix={<UserOutlined color="#ccc" />}
                className="rounded-full"
              />
            </Form.Item>
            <Form.Item name="password" label="Password">
              <Input.Password
                placeholder="Password"
                prefix={<KeyOutlined color="#ccc" />}
                className="rounded-full"
              />
            </Form.Item>
            <Button className="w-full" type="primary" htmlType="submit">
              Login
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
}
