import { PageContext } from '../../../lib/context';
import FeedsView from './feeds';
import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import queries from '../../../utils/gql/queries';
import { Button, Form, message, Modal, Space, Tag, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import mutations from '../../../utils/gql/mutations';
import auth from '../../../lib/services';

export default function FeedController() {

  interface Sort {
    id: string | null | boolean;
    description: any;
    category: any;
    is_public: any;
  }

  const [getFeeds, { data }] = useLazyQuery(queries.GET_FEEDS, {
    fetchPolicy: 'no-cache',
  });

  const [deleteFeed] = useMutation(mutations.DELETE_FEED, {
    onCompleted(res) {
      message.success('Successful!');
      _getFeeds();
    },
    onError() {
      message.error('Failed!');
    },
  });
  const [feeds, setFeeds] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [sort, setSort] = useState<any>(null);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [showMeter, setShowMeter] = useState<boolean>(true);
  const [sortOrder, setSortOrder] = useState<Sort>({
    id: false,
    description: false,
    is_public: false,
    category: false,
  });

  useEffect(() => {
    getFeeds();
  }, [getFeeds]);

  useEffect(() => {
    if (data) {
      setFeeds(data.data);
      setRefreshing(false);
    }
  }, [data]);

  const handleSort = (pagination: any, filters: any, sorter: any) => {
    const sorterOrder: any = {
      ascend: 'asc',
      descend: 'desc',
    };
    if (sorter.columnKey === 'description') {
      setSort({
        description: sorterOrder[sorter.order],
      });
      setSortOrder({
        ...sortOrder,
        description: sorter.order,
      });
    } else if (sorter.columnKey === 'category') {
      setSort({
        category: sorterOrder[sorter.order],
      });
      setSortOrder({
        ...sortOrder,
        category: sorter.order,
      });
    } else {
      console.log(sorter.columnKey);
      setSort({
        [sorter.columnKey]: sorterOrder[sorter.order],
      });
      setSortOrder({ ...sortOrder, [sorter.columnKey]: sorter.order });
    }
  };

  useEffect(() => {
    _getFeeds();
  }, [sort]);

  const _getFeeds = () => {
    getFeeds();
  };

  const { confirm } = Modal;
  const [form] = Form.useForm();

  const _submitHandler = () => {
    // alert('test')
    form.submit();
  };

  const _showEditHandler = (data: any) => {
    return () => {
      form.setFieldsValue(data);
      _showHandler();
    };
  };

  const _showHandler = () => {
    setShowForm(true);
    setShow(true);
  };

  const _closeHandler = () => {
    setShow(false);
    setShowForm(false);
    setShowMeter(true);
    form.resetFields();
  };

  const [insertUpdateFeed] = useMutation(mutations.INSERT_UPDATE_FEED, {
    onCompleted(res) {
      message.success('Success');
      getFeeds();
      _closeHandler();
    },
    onError({ graphQLErrors }) {
      message.error('Update failed.');
      console.error(graphQLErrors[0].message)
    },
  });


  const _onFinish = async (data: any) => {
    if (data.picture) {
      console.log(data.picture.file.originFileObj)
      const { url }: any = await auth.cloudinary(data.picture.file.originFileObj)

      data.image = url
    }

    delete data.picture

    insertUpdateFeed({
      variables: {
        data,
      },
    });
  };

  const usersColumns = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      sortOrder: sortOrder.id,
    },
    {
      title: 'Public',
      dataIndex: 'is_public',
      key: 'is_public',
      sortOrder: sortOrder.is_public,
      render: (is_public: any) => (
        <Tag className='capitalize' color={is_public ? 'green' : 'magenta'} > {is_public ? 'true' : 'false'}</Tag>
      )
    },
    {
      title: 'Description',
      key: 'description',
      dataIndex: 'description',
      sortOrder: sortOrder.description,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      sortOrder: sortOrder.category,
      render: (category: any) => (
        <Tag color={category.color} className='capitalize'>{category.name}</Tag>
      )
    },
    {
      title: 'Action',
      key: 'action',
      render: (record: any) => (
        <Space>
          <Tooltip title="Edit">
            <Button type="link" onClick={_showEditHandler(record)}>
              <EditOutlined />
            </Button>
          </Tooltip>
          <Tooltip title="Delete Subscriber">
            <Button
              type="link"
              size="middle"
              icon={<DeleteOutlined />}
              style={{ width: 50 }}
              onClick={() => {
                confirm({
                  title: 'This will delete the selected feed.',
                  onOk() {
                    deleteFeed({
                      variables: {
                        id: record.id,
                      },
                    });
                  },
                });
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const value: object = {
    datas: feeds,
    usersColumns,
    show,
    form,
    showForm,
    handleSort,
    _onFinish,
    _submitHandler,
    _showHandler,
    _closeHandler,
  };
  return (
    <PageContext.Provider value={value}>
      <FeedsView />
    </PageContext.Provider>
  )
}
