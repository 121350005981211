import {
  Button,
  Col,
  Drawer,
  Form,
  InputNumber,
  Row,
  Select,
  Upload,
  message,
} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { UploadProps } from 'antd/es/upload';
import TextArea from 'antd/lib/input/TextArea';
import { RcFile } from 'antd/lib/upload';
import queries from '../../../utils/gql/queries';
import React, { useEffect, useState } from 'react';
import mutations from '../../../utils/gql/mutations';
import auth from '../../../lib/services';

const ToFeeds = ({ show, width = 800, handleClose, report }: any) => {
  const [form] = Form.useForm();
  const [imageUrl, setImageUrl] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const public_choices = [
    {
      value: true,
      text: 'YES',
    },
    {
      value: false,
      text: 'No',
    },
  ];

  const [getCategories, { data }] = useLazyQuery(queries.GET_CATEGORIES, {
    fetchPolicy: 'no-cache',
  });

  const [insertUpdateFeed] = useMutation(mutations.INSERT_UPDATE_FEED, {
    onCompleted(res) {
      message.success('Success');
      handleClose();
    },
    onError({ graphQLErrors }) {
      message.error('Update failed.');
      console.error(graphQLErrors[0].message);
    },
  });

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  useEffect(() => {
    if (data) {
      setCategories(data.data);
    }
  }, [data]);

  useEffect(() => {
    if (report) {
      form.setFieldsValue(report);
      console.log(report, 'report');
    }
  }, [report]);

  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const handleChange: UploadProps['onChange'] = (info: any) => {
    getBase64(info.file.originFileObj as RcFile, url => {
      setLoading(false);
      setImageUrl(url);
    });
  };

  const handleSubmit = () => {
    form.submit();
  };

  const _onFinish = async (data: any) => {
    if (data.picture) {
      console.log(data.picture.file.originFileObj);
      const { url }: any = await auth.cloudinary(
        data.picture.file.originFileObj
      );

      data.image = url;
    }

    delete data.picture;

    insertUpdateFeed({
      variables: {
        data,
      },
    });
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  return (
    <>
      <Drawer
        visible={show}
        width={width}
        closable={true}
        onClose={handleClose}
        title={'Create a Feeds'}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button onClick={handleClose.bind(this)} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button onClick={handleSubmit} type="primary">
              Submit
            </Button>
          </div>
        }
      >
        <Form layout="vertical" form={form} onFinish={_onFinish}>
          <Row gutter={24}>
            <Form.Item name="image" hidden={true}>
              <InputNumber />
            </Form.Item>
            <Col span={24}>
              <Form.Item name="picture">
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader text-center mt-5"
                  showUploadList={false}
                  onChange={handleChange}
                >
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="avatar"
                      style={{ width: '100%' }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Description"
                name="description"
                rules={[{ required: true, message: 'Description is required' }]}
              >
                {/* <Input /> */}
                <TextArea />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Category"
                name="category_id"
                rules={[{ required: true, message: 'Category is required' }]}
              >
                <Select
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {categories.map(({ id, name }: any) => (
                    <Select.Option key={`${id}`} value={id}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Public Feeds ?"
                name="is_public"
                rules={[
                  { required: true, message: 'Public Field is required' },
                ]}
              >
                <Select
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {public_choices.map(({ value, text }: any) => (
                    <Select.Option key={`${value}`} value={value}>
                      {text}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default ToFeeds;
