import { withRouter } from 'react-router-dom';
import auth from '../lib/services';

const AuthVerifyComponent = ({ history }: any) => {
  history.listen(() => {
    // <--- Here you subscribe to the route change
    if (auth.isAuthenticated()) {
      if (auth.getExpiration() * 1000 < Date.now()) {
        auth.clear();
        window.location.href = '/';
      }
      // TODO: add token refresh
    }
  });
  return null;
};

export default withRouter(AuthVerifyComponent);
