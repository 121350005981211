import {
  Col,
  Row,
  Form,
  Input,
  Select,
  Switch,
  InputNumber,
  Divider,
  Button,
} from 'antd';
import { useContext } from 'react';
import { PageContext } from '../../../lib/context';

const CustomerForm = () => {
  const {
    meters,
    municipalities,
    barangays,
    rates,
    _switchHandler,
    form,
    _onFinish,
    showMeter,
    _formValueChange,
  } = useContext(PageContext);

  return (
    <Form layout="vertical" form={form} onFinish={_onFinish}>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item name="id" hidden={true}>
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="First Name"
            name="first_name"
            rules={[{ required: true, message: 'First name is required' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Middle Name"
            name="middle_name"
            rules={[{ required: true, message: 'Middle name is required' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Last Name"
            name="last_name"
            rules={[{ required: true, message: 'Last name is required' }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Mobile Number"
            name="mobile_number"
            rules={[{ required: true, message: 'Mobile number is required' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Consumer Type"
            name="consumer_type"
            rules={[{ required: true, message: 'Consumer is required' }]}
            initialValue={1}
          >
            <InputNumber style={{ width: '100%' }} disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Municipality"
            name="municipality"
            rules={[{ required: true, message: 'Municipality is required' }]}
          >
            <Select
              showSearch
              filterOption={(input: any, option: any) =>
                option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={municipalities}
              onChange={() => _formValueChange()}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Barangay"
            name="barangay"
            rules={[{ required: true, message: 'Barangay is required' }]}
          >
            <Select
              showSearch
              filterOption={(input: any, option: any) =>
                option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={barangays}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Complete Address"
            name="complete_address"
            rules={[
              { required: true, message: 'Complete address is required' },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Zip Code"
            name="zip_code"
            rules={[{ required: true, message: 'Zip code is required' }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Rate"
            name="rates_id"
            rules={[{ required: true, message: 'Rate is required' }]}
          >
            <Select
              showSearch
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {rates.map(({ id, name }: any) => (
                <Select.Option key={`${id}`} value={id}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {/* <Col span={12}>
          <Form.Item
            label="Meter"
            name="meter_id"
            rules={[{ required: true, message: 'Meter is required' }]}
            hidden={!showMeter}
          >
            <Select
              showSearch
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {meters.map(({ id, serial_number }: any) => (
                <Select.Option key={`${id}`} value={id}>
                  {serial_number}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col> */}
        {/* <Divider></Divider>
          <Col span={4}>
            <Button
              className="flex flex-col place-items-center"
              onClick={() => {
                setShowQR(true);
              }}
            >
              Barcode Scanner
            </Button>
            <Modal
              title="Barcode Scanner"
              centered
              visible={showQR}
              closable
              footer={() => <div></div>}
              onCancel={() => setShowQR(false)}
            >
              <div className="self-center justify items center mb-5">
                <BarcodeScannerComponent
                  width={500}
                  height={500}
                  onUpdate={(err, result: any) => _onFinishScan(result?.text)}
                />
              </div>
            </Modal>
          </Col> */}
        <Divider></Divider>
        {/* <Col span={8}>
            <div>Installation Fees</div>
          </Col>
          <Divider></Divider>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Mode of Payment"
                name="mode_of_payment"
                rules={[
                  { required: true, message: 'Mode of payment is required.' },
                ]}
              >
                <Select
                  style={{ width: 300 }}
                  onChange={handleChange}
                  placeholder="Select mode of payment"
                >
                  <Option value="Straight Payment">Straight Payment</Option>
                  <Option value="Installment">Installment</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Number of Months"
                name="number_of_months"
                initialValue="1"
              >
                <Select
                  style={{ width: 300 }}
                  onChange={handleMonthChange}
                  disabled={display}
                >
                  <Option value="1">1 Month</Option>
                  <Option value="12">12 Months</Option>
                  <Option value="24">24 Months</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row> */}
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="Active Account?">
            <Switch defaultChecked onChange={_switchHandler} />
          </Form.Item>
        </Col>
      </Row>
      <Button htmlType="submit" type="primary">
        SUBMIT
      </Button>
    </Form>
  );
};

export default CustomerForm;
